@import 'src/assets/scss/partials/_vars';
@import 'src/assets/scss/partials/_mixins';

/* Generic styles */
html,
body,
#root {
	height: 100%;
	background: $c_body;
}

.title {
	margin-bottom: 25px;
}

.error {
	border: 1px solid red;
}

.error-message {
	display: none;
}

.show-error-message {
	display: block;
}

.clearfix:after {
	content: '';
	display: table;
	clear: both;
}

.hidden {
	display: none;
	opacity: 0;
	@include transition(opacity, 0.5s, ease-in);
}

/* App styles */
.app-wrapper {
	min-height: 100%;
	height: auto;
	background: $c_body;
}

.App {
	text-align: center;
}

.App-body {
	transition: margin-left 0.5s;
	padding: 20px;
	padding-top: 65px;
	position: relative;
	@include transition(left, 0.55s, ease);
	left: 0;

	&.nav-is-open {
		left: 250px;
	}
}

.btn.pulsing,
.auto-generate-btn.pulsing {
	@include animate(pulsing, 500ms, infinite, linear);
}

.fa-spin {
	font-size: 30px;
	color: #333;
	@include animate(fa-spin, 2s, infinite, linear);
}

@media screen and (max-height: 450px) {
	.side-nav {
		padding-top: 15px;
	}
	.side-nav a {
		font-size: 18px;
	}
}

@keyframes fa-spin {
	0% {
		@include rotate(0);
	}
	100% {
		@include rotate(359);
	}
}

@keyframes pulsing {
	0% {
		opacity: 1;
		color: #ffffff;
	}
	50% {
		opacity: 0.75;
		color: #ffb2ad;
	}
	100% {
		opacity: 1;
		color: #ffffff;
	}
}
