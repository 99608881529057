@import 'src/assets/scss/partials/_vars';
@import 'src/assets/scss/partials/_mixins';

.my-events-page {
	.title {
		margin-bottom: 25px;

		.btn {
			padding: 10px 36px 10px 18px !important;
			background: $cp_primary !important;
			float: right;
			margin-top: 5px;
			margin-right: 8%;
			position: relative;

			&.with-events {
				margin-right: 2%;
			}

			.arrow-icon {
				position: absolute;
				top: 10px;
				right: 10px;
				font-size: 16px;
			}
		}
	}
}

.create-event-form {
	.react-date-picker {
		height: calc(2.25rem + 2px);
		width: 100%;
	}

	.react-date-picker__wrapper {
		padding: 0 10px;
		border: thin solid #ccc;
		-webkit-border-radius: 5px;
		border-radius: 5px;
		width: 100%;
	}
}

.loading-wrapper {
	text-align: center;

	.fa-spin {
		color: $cp_primary;
	}
}
