@import 'src/assets/scss/partials/_vars';
@import 'src/assets/scss/partials/_mixins';

.feedback-wrapper {
	position: fixed;
	bottom: 15%;
	right: 0;
	-webkit-transition: right 0.5s ease;
	-o-transition: right 0.5s ease;
	transition: right 0.5s ease;
	display: block;
	opacity: 1;

	&.fhidden {
		right: -360px;
	}

	.feedback {
		position: relative;

		.feedback-floating-btn {
			position: absolute;
			width: 100%;
			height: 100%;
			width: 70px;
			height: 65px;
			left: -70px;
			top: 0;
			opacity: 0.1;

			.btn {
				width: 100%;
				height: 100%;
				padding: 0;
				margin: 0;
				background: $cp_primary !important;
				border-radius: 0.3rem 0 0 0.3rem;
				box-shadow: none;
				font-size: 10px;

				.arrow-icon {
					vertical-align: middle;
					position: relative;
					font-size: 2.25rem;
				}
			}
		}

		.feedback-floating-btn:hover {
			opacity: 1;
		}

		.feedback-form-container {
			background: $cp_white;
			padding: 15px 20px;
			border-radius: 0 0 0.3rem 0.3rem;
			border: 1px solid $c_border;

			textarea {
				width: 100%;
				padding: 7px;
				height: 150px;
			}

			.submit-btn-container {
				text-align: center;
				margin-bottom: 0;

				.btn {
					margin: 0;
					background: $cp_primary !important;
				}
			}
		}
	}
}
