.filters-wines {
	background: #fff;
	padding: 0;
	font-size: 1.1em;
	font-weight: 400;
	border: 1px solid #ccc;
	color: #666666;

	.filters-header {
		text-align: center;
		line-height: 1.8em;
		font-size: 1.15em;
		font-weight: 400;
		border-bottom: 1px solid #ccc;
		padding: 5px;
		margin: 0;
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	.filter-list-key {
		background: #fff;
		line-height: 1.8em;
		border-bottom: 1px solid #ccc;
		padding: 5px 0 5px 10px;
		position: relative;

		&:hover {
			cursor: pointer;
		}

		.drop-arrow {
			position: absolute;
			top: 50%;
			right: 15px;
			width: 20px;
			height: 20px;
			margin-top: -10px;
			border-radius: 100px;
			cursor: pointer;

			@mixin arrows() {
				background-color: #cccccc;
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				width: 8px;
				height: 2px;
				margin-top: -1px;
				margin-left: -4px;
				background-color: #cccccc;
				-webkit-transition: all 150ms linear;
				-moz-transition: all 150ms linear;
				-ms-transition: all 150ms linear;
				-o-transition: all 150ms linear;
				transition: all 150ms linear;
			}

			&::before {
				@include arrows();
				right: 0px;
				transform: rotate(-45deg);
			}

			&::after {
				@include arrows();
				right: 5px;
				transform: rotate(45deg);
			}
		}
	}

	.filter {
		&.unfolded {
			.filter-list-key .drop-arrow {
				&::before {
					background-color: #ff645a;
					right: 0px;
					transform: rotate(45deg);
				}

				&::after {
					background-color: #ff645a;
					right: 5px;
					transform: rotate(-45deg);
				}
			}

			.filter-group {
				padding-top: 10px;
				padding-bottom: 10px;
				height: 115px;
			}
		}

		&.folded .filter-group {
			height: 0;
		}
	}

	.filter-group {
		background: #f8f8f8;
		line-height: 1.8em;
		padding-left: 25px;
		margin-bottom: 10px;
		overflow: hidden;
		-webkit-transition: height 0.35s ease;
		-moz-transition: height 0.35s ease;
		-ms-transition: height 0.35s ease;
		-o-transition: height 0.35s ease;
		transition: height 0.35s ease;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
