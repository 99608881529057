@import 'src/assets/scss/partials/_vars';
@import 'src/assets/scss/partials/_mixins';

/* BoxSelection */
.box-selection {
	margin-bottom: 35px;

	.box-selection-title {
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: $c_text;
	}
}

.box-selection-wrapper {
	.row {
		background-color: $cp_grey;
		border: 1px solid $c_border;
		@include border_radius(5px);
		@include b-s(0, 1px, 0, 0, $c_box_shadow);
	}
}

.box-selection-option {
	text-align: center;
	padding: 0 !important;
	border-right: 1px solid $c_border;

	&:last-child {
		border-right: 0;
	}

	label {
		padding: 25px 20px;
		width: 100%;
		height: 100%;
	}

	.option-color-pallette {
		height: 50px;
		width: 50px;
		background: #ccc;
		@include border_radius(25px);
		margin: 12px auto;
	}
}
