@import 'src/assets/scss/partials/_vars';
@import 'src/assets/scss/partials/_mixins';

.not-found-page {
	text-align: center;

	.content {
		@include border_radius(5px);
		background: $cp_grey_light;
		padding: 30px;
		position: relative;
		border: 1px solid $c_border;
		min-height: 500px;

		p {
			margin-bottom: 5px;
		}
	}

	.header-404 {
		font-weight: bolder;
		font-size: 20em;
		color: $cp_primary;
	}

	.header-not-found {
		margin-bottom: 35px;
		font-size: 3em;
	}

	.home-page-link {
		color: $cp_primary;
	}
}
