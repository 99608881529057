@import 'src/assets/scss/partials/_vars';

.group-details {
	.my-groups-btn-wrapper {
		.arrow-icon {
			position: relative;
			top: -2px;
			left: -11px;
			font-size: 20px;
		}

		.btn {
			margin: 0;
			padding: 0 2.14rem;
			border-radius: 0.125rem 0.125rem 0 0;
			line-height: 0.84rem;
			font-size: 18px;
			height: 40px;
			line-height: 40px;
			background: $cp_primary !important;
		}
	}

	.group-info {
		position: relative;

		.edit-btn {
			position: absolute;
			top: 0;
			right: 0;
			padding: 5px 10px;
			border: 0;
			background: $cp_primary !important;
			color: #fff;

			&:hover {
				cursor: pointer;
			}
		}

		.save-btn {
			padding: 5px 20px;
			border: 0;
			background: $cp_primary !important;
			color: #fff;
			margin-right: 10px;

			&:hover {
				cursor: pointer;
			}
		}

		.disabled {
			background: $c_border !important;
		}

		.group-about {
			margin-bottom: 20px;
		}

		.group-handle {
			&.edit {
				padding: 0 5px;
			}
		}

		.group-description {
			padding: 5px;
		}

		.loading-wrapper {
			display: inline-block;
		}
	}
}
