@import 'src/assets/scss/partials/_vars';
@import 'src/assets/scss/partials/_mixins';

.rating-total {
	@include border_radius(100px);
	margin: 0 auto;
	width: 100px;
	height: 100px;
	border: 1px solid $cp_primary;
	text-align: center;
	padding-top: 27px;
	color: #777;

	.rp-digits {
		font-size: 36px;
		line-height: 0.85;
		margin: 0;
		font-weight: normal;
	}

	.rp-label {
		font-size: 12px;
		font-weight: normal;
		margin: 0;
	}
}
