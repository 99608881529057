@import '../_vars';
@import '../_mixins';

.comments-step {
	.comment-area-wrapper {
		margin-bottom: 45px;
	}
}

.comment-area {
	border: 1px solid $c_border;
	@include border_radius(3px);
	overflow: hidden;
	background: $cp_white;

	.ca-header {
		text-align: center;
		font-size: 20px;
		color: $c_text;
		font-weight: normal;
		border-bottom: 1px solid $c_border;
		height: 53px;
		line-height: 53px;
		margin-bottom: 0;
	}

	.ca-input {
		width: 100%;
		padding: 15px;
		background: $cp_grey;
		border-bottom: 1px solid $c_border;
		position: relative;

		textarea {
			width: 100%;
			border: 1px solid $c_border;
			@include border_radius(3px);
			min-height: 150px;
			padding: 20px 15px;
		}

		.ca-mini-label {
			position: absolute;
			top: 18px;
			right: 21px;
			font-size: 12px;
		}
	}

	.ca-controls {
		text-align: center;
		height: 60px;
		line-height: 60px;

		button {
			padding: 9px 15px;
			margin: 0 12px;
			border-collapse: separate;
			line-height: 1rem;
			@include border_radius(3px);

			&:hover {
				cursor: pointer;
			}

			&.undo-btn {
				border: 1px solid transparent;
				box-shadow: none;
				background-color: transparent;
				color: $cp_primary;
			}

			&.undo-btn:hover {
				border-color: $cp_primary;
			}

			&.auto-generate-btn {
				border: 0;
				background: $cp_primary;
				border: 1px solid $cp_primary;
				color: $cp_white;
				opacity: 1;
			}

			&.auto-generate-btn.disable {
				border: 0;
				background: none;
				border: 1px solid $c_border;
				color: #ddd;
				animation: none;
			}
		}
	}
}
