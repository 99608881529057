.check-box-wrapper {
	position: relative;

	&.has-selection {
		input[type='checkbox'] + .simbox {
			border: 1px solid #ff645a;
			background-color: white;

			div {
				transform: rotate(0);
				position: absolute;
				margin-top: -1px;
				margin-left: -7px;
				top: 50%;
				left: 50%;
				width: 14px;
				height: 3px;
				transition: all 150ms linear;

				&.front {
					background-color: white;
					margin-top: -15px;
					z-index: 2;
				}

				&.back {
					background-color: #ff645a;
					z-index: 1;
				}
			}
		}
	}

	input {
		position: absolute;
		left: -9999px;
		box-sizing: border-box;
		padding: 0;
		overflow: visible;

		& + .simbox {
			border: 1px solid #cccccc;
			position: absolute;
			left: 10px;
			width: 20px;
			height: 20px;
			top: 50%;
			margin-top: -10px;
			margin-bottom: 0;
			pointer-events: none;
			overflow: hidden;
			box-sizing: border-box;
		}
	}

	label {
		position: relative;
		display: inline-block;
		min-width: 20px;
		min-height: 20px;
		background-color: transparent;
		cursor: pointer;
		padding-left: 40px;
		margin-bottom: 0;
	}

	input[type='checkbox'] {
		& + .simbox {
			background-color: #cccccc;
			border-radius: 3px;
			transition: all 150ms linear;

			div {
				transform: rotate(45deg);
				position: absolute;
				margin-top: -15px;
				margin-left: -15px;
				top: 50%;
				left: 50%;
				width: 30px;
				height: 30px;
				transition: all 150ms linear;

				&.front {
					background-color: #cccccc;
					margin-top: -55px;
					z-index: 2;
				}

				&.back {
					background-color: white;
					z-index: 1;
				}
			}
		}

		&:checked {
			& + .simbox {
				border: 1px solid #ff645a;
				background-color: #ff645a;

				div {
					transform: rotate(45deg);

					&.front {
						background-color: #ff645a;
						width: 10px;
						height: 12px;
						margin-top: -13px;
						margin-left: -5px;
						background-color: #ff645a;
					}

					&.back {
						background-color: white;
						width: 9px;
						height: 12px;
						margin-top: -9px;
						margin-left: -4px;
					}
				}
			}
		}
	}
}

* > .check-box-wrapper .simbox {
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
	transition: box-shadow 250ms ease-in-out;
}
