@import 'src/assets/scss/partials/_vars';
@import 'src/assets/scss/partials/_mixins';

.my-groups-page {
	.title {
		margin-bottom: 25px;

		.btn {
			padding: 10px 36px 10px 18px !important;
			background: $cp_primary !important;
			float: right;
			margin-top: 5px;
			margin-right: 8%;
			position: relative;

			&.with-groups {
				margin-right: 2%;
			}

			.arrow-icon {
				position: absolute;
				top: 10px;
				right: 10px;
				font-size: 16px;
			}
		}
	}
}

.loading-wrapper {
	text-align: center;

	.fa-spin {
		color: $cp_primary;
	}
}
